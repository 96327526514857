// extracted by mini-css-extract-plugin
export var cardContainer = "style-module--card-container--i99HO";
export var cardCover = "style-module--card-cover--0OmMH";
export var cardExtra = "style-module--card-extra--BXkN0";
export var cardGenre = "style-module--card-genre--RF+xy";
export var cardInfo = "style-module--card-info--mgPrJ";
export var cardName = "style-module--card-name--hNRQj";
export var cardNoCover = "style-module--card-no-cover--pRvyA";
export var cardRole = "style-module--card-role--0xe9r";
export var cardStatus = "style-module--card-status--kQ9O2";
export var cardWrapper = "style-module--card-wrapper--KIJN+";